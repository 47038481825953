import { combineReducers, createStore } from 'redux';
import langReducer from './reducers/langReducer';
import newPersonFormReducer from './reducers/newPersonFormReducer';
import userGuidReducer from './reducers/userGuidReducer';
import countriesReducer from './reducers/countriesReducer';
import provincesReducer from './reducers/provincesReducer';
import notificationReducer from './reducers/notificationReducer';
import anchorNavigationReducer from './reducers/anchorNavigationReducer';

const rootReducer = combineReducers({
    lang: langReducer,
    newPersonForm: newPersonFormReducer,
    userGuid: userGuidReducer,
    countries: countriesReducer,
    provinces: provincesReducer,
    notification: notificationReducer,
    anchorNavigation: anchorNavigationReducer
});

const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
