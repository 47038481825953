import React from 'react';
import NewPersonForm from '../newPersonForm/NewPersonForm';
import MainSideBar from '../mainSideBar/MainSideBar';
import styles from './NewContractPage.module.scss'
import { Spinner, SpinnerSize } from '@fluentui/react';

interface INewContractPage {
    isLoading: boolean;
    isLoadedAllData: boolean;
    message: string;
}

const NewContractPage = ({ isLoading, isLoadedAllData, message }: INewContractPage): JSX.Element => {
    return (
        <>
            {!isLoadedAllData && <div className={styles.spinner}> {isLoading && <Spinner size={SpinnerSize.large} />} <p>{message}</p>
            </div>}
            {isLoadedAllData && <div className={styles.newContractPageWrapper}>
                <div className={styles.newContractPageInnerWrapper}>
                    <div className={styles.mainSideBarWrapper}>
                        <MainSideBar />
                    </div>
                    <div className={styles.newPersonWrapper}>
                        <NewPersonForm />
                    </div>
                </div>
            </div>}
        </>)
}

export default NewContractPage;