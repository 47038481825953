export const SET_USER_GUID = 'SET_USER_GUID';

export interface UserGuidState {
    userGuid: string;
}

interface SetUserGuidAction {
    type: typeof SET_USER_GUID;
    payload: string;
}

export type UserGuidAction = SetUserGuidAction;
