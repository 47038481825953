import { INotification } from '../../models/interfaces/INotification';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export interface NotificationState {
    notification: INotification;
}

interface SetNotification {
    type: typeof SET_NOTIFICATION;
    payload: INotification;
}

export type NotificationAction = SetNotification;
