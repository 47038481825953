export class Company {
    name = '';
    countryId = 0;
    address = '';
    postCode = '';
    city = '';
    nip = '';
    regon = '';
    vatTaxPayer = false;
    euVatNumber = '';
}
