import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { UserGuidAction, SET_USER_GUID } from '../actionTypes/userGuidTypes';

export const setUserGuid = (userGuid: string): UserGuidAction => {
    localStorage.setItem(LocalStorageNames.userGuid, userGuid);

    return {
        type: SET_USER_GUID,
        payload: userGuid
    };
};
