import React, { FormEvent, useEffect, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from './AddressForm.module.scss';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PolandId } from './../../constants/constants';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react/lib/components/ComboBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { ITerritorialUnit } from '../../models/interfaces/ITerritorialUnit';
import { IAddressTerritorialUnitId } from '../../models/interfaces/IAddressTerritorialUnitId';
import { RequestService } from '../../services/RequestService';
import { Endpoints } from '../../services/const.routes';
import { TooltipHost } from '@fluentui/react/lib/components/Tooltip';
import { useId } from '@fluentui/react-hooks';

interface IAdressFormProps {
    formik: FormikProps<INewPerson>;
    getFieldProps: (formik, field) => void;
    getErrorFieldProps: (formik, field) => void | { errorMessage: string };
    handleOnComboChangeValue: (
        formik,
        fieldName
    ) => (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string) => void;
    countryOptions: IDropdownOption[];
    parentObjectName?: string;
    title?: string;
    displayCountry?: boolean;
}

const territorialUnitsRequestService = new RequestService<ITerritorialUnit>();

const Divider = ' - ';
const CountryId = 'countryId';
const Province = 'province';
const District = 'district';
const Commune = 'commune';
const City = 'city';
const PostCode = 'postCode';
const RegistrationPostOfficeCity = 'registrationPostOfficeCity';
const Street = 'street';
const HouseNumber = 'houseNumber';
const FlatNumber = 'flatNumber';

const AdressForm = ({
    formik,
    getFieldProps,
    getErrorFieldProps,
    handleOnComboChangeValue,
    countryOptions,
    parentObjectName,
    title,
    displayCountry = true
}: IAdressFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [isPoland, setIsPoland] = useState<boolean>();
    const [districts, setDistricts] = useState<ITerritorialUnit[]>([]);
    const [communes, setCommunes] = useState<ITerritorialUnit[]>([]);
    const [cities, setCities] = useState<ITerritorialUnit[]>([]);
    const [streets, setStreets] = useState<ITerritorialUnit[]>([]);

    const [provincesOptions, setProvincesOptions] = useState<IDropdownOption[]>([]);
    const [districtsOptions, setDistrictsOptions] = useState<IDropdownOption[]>([]);
    const [communesOptions, setCommunesOptions] = useState<IDropdownOption[]>([]);
    const [citiesOptions, setCitiesOptions] = useState<IDropdownOption[]>([]);
    const [streetsOptions, setStreetsOptions] = useState<IDropdownOption[]>([]);

    const { provinces } = useSelector((state: RootState) => state.provinces);

    // Tooltips
    const districtTooltipId = useId('districtTooltipId');
    const provinceTooltipId = useId('provinceTooltipId');
    const communeTooltipId = useId('communeTooltipId');
    const cityComboTooltipId = useId('cityComboTooltipId');
    const cityTextTooltipId = useId('cityTextTooltipId');
    const postCodeTooltipId = useId('postCodeTooltipId');
    const postOfficeCityTooltipId = useId('postOfficeCityTooltipId');
    const streetComboTooltipId = useId('streetComboTooltipId');
    const streetTextTooltipId = useId('streetTextTooltipId');
    const houseNumberTooltipId = useId('houseNumberTooltipId');
    const flatNumberTooltipId = useId('flatNumberTooltipId');

    useEffect(() => {
        parentObjectName ? setIsPoland(formik.values[parentObjectName][CountryId] == PolandId) : setIsPoland(formik.values[CountryId] == PolandId);
    }, [parentObjectName ? formik.values[parentObjectName][CountryId] : formik.values[CountryId]]);

    useEffect(() => {
        const addressTerritorialUnitId = getAddressTerritorialUnitId();

        if (addressTerritorialUnitId.provinceId) {
            loadDistricts(addressTerritorialUnitId.provinceId);
        }
    }, [parentObjectName ? formik.values[parentObjectName][Province] : formik.values[Province]]);

    useEffect(() => {
        const addressTerritorialUnitId = getAddressTerritorialUnitId();

        if (addressTerritorialUnitId.provinceId && addressTerritorialUnitId.districtId) {
            loadCommunes(addressTerritorialUnitId.provinceId, addressTerritorialUnitId.districtId);
        }
    }, [parentObjectName ? formik.values[parentObjectName][District] : formik.values[District], districts]);

    useEffect(() => {
        const addressTerritorialUnitId = getAddressTerritorialUnitId();

        if (addressTerritorialUnitId.provinceId && addressTerritorialUnitId.districtId && addressTerritorialUnitId.communeId) {
            loadCities(addressTerritorialUnitId.provinceId, addressTerritorialUnitId.districtId, addressTerritorialUnitId.communeId);
        }
    }, [parentObjectName ? formik.values[parentObjectName][Commune] : formik.values[Commune], communes]);

    useEffect(() => {
        const addressTerritorialUnitId = getAddressTerritorialUnitId();

        if (
            addressTerritorialUnitId.provinceId &&
            addressTerritorialUnitId.districtId &&
            addressTerritorialUnitId.communeId &&
            addressTerritorialUnitId.cityId
        ) {
            loadStreets(
                addressTerritorialUnitId.provinceId,
                addressTerritorialUnitId.districtId,
                addressTerritorialUnitId.communeId,
                addressTerritorialUnitId.cityId
            );
        }
    }, [parentObjectName ? formik.values[parentObjectName][City] : formik.values[City], cities]);

    useEffect(() => {
        loadProvinces();
    }, []);

    useEffect(() => {
        const districtOptions: IDropdownOption[] = districts.map((districts: ITerritorialUnit) => {
            return { key: districts.name, text: districts.name };
        });

        setDistrictsOptions(districtOptions);
    }, [districts]);

    useEffect(() => {
        const comunesOptions: IDropdownOption[] = communes.map((commune: ITerritorialUnit) => {
            return {
                key: `${commune.name}${Divider}${commune.territorialUnitType}`,
                text: `${commune.name}${Divider}${commune.territorialUnitType}`
            };
        });

        setCommunesOptions(comunesOptions);
    }, [communes]);

    useEffect(() => {
        const citiesOptions: IDropdownOption[] = cities.map((city: ITerritorialUnit) => {
            return {
                key: `${city.name}${Divider}${city.territorialUnitType}`,
                text: `${city.name}${Divider}${city.territorialUnitType}`
            };
        });

        setCitiesOptions(citiesOptions);
    }, [cities]);

    useEffect(() => {
        const streetsOptions: IDropdownOption[] = streets.map((street: ITerritorialUnit) => {
            return {
                key: street.name,
                text: street.name
            };
        });

        const currentStreetValue = parentObjectName ? formik.values[parentObjectName][Street] : formik.values[Street];

        if (currentStreetValue && !streetsOptions.find((streetsOption) => streetsOption.key == currentStreetValue)) {
            streetsOptions.unshift({
                key: parentObjectName ? formik.values[parentObjectName][Street] : formik.values[Street],
                text: parentObjectName ? formik.values[parentObjectName][Street] : formik.values[Street]
            });
            streetsOptions.sort((a, b) => a.text.localeCompare(b.text));
        }

        setStreetsOptions(streetsOptions);
    }, [streets]);

    const loadProvinces = async () => {
        const provinceOptions: IDropdownOption[] = provinces.map((province: ITerritorialUnit) => {
            return { key: province.name, text: province.name };
        });

        setProvincesOptions(provinceOptions);
    };

    const getAddressTerritorialUnitId = (): IAddressTerritorialUnitId => {
        const province = parentObjectName ? formik.values[parentObjectName][Province] : formik.values[Province];
        const district = parentObjectName ? formik.values[parentObjectName][District] : formik.values[District];
        const commune = parentObjectName ? formik.values[parentObjectName][Commune] : formik.values[Commune];
        const city = parentObjectName ? formik.values[parentObjectName][City] : formik.values[City];
        const provinceId = provinces.find((territorialUnit) => territorialUnit.name == province)?.id;
        const districtId = districts.find((territorialUnit) => territorialUnit.name == district)?.id;

        const communeName = commune.substring(0, commune.lastIndexOf(Divider));
        const communeType = commune.substring(commune.lastIndexOf(Divider) + Divider.length);
        const communeId = communes.find((territorialUnit) => territorialUnit.name == communeName && territorialUnit.territorialUnitType == communeType)?.id;

        const cityName = city.substring(0, city.lastIndexOf(Divider));
        const cityType = city.substring(city.lastIndexOf(Divider) + Divider.length);
        const cityId = cities.find((territorialUnit) => territorialUnit.name == cityName && territorialUnit.territorialUnitType == cityType)?.id;

        return {
            provinceId: provinceId,
            districtId: districtId,
            communeId: communeId,
            cityId: cityId
        };
    };

    const loadDistricts = async (provinceId: string | number) => {
        if (provinceId) {
            const districts = await territorialUnitsRequestService.getList(`${Endpoints.Districts}/${provinceId}`);
            setDistricts(districts);
        }
    };

    const loadCommunes = async (provinceId: string | number, districtId: string | number) => {
        if (provinceId && districtId) {
            const communes = await territorialUnitsRequestService.getList(`${Endpoints.Communes}/${provinceId}/${districtId}`);
            setCommunes(communes);
        }
    };

    const loadCities = async (provinceId: string | number, districtId: string | number, communeId: string | number) => {
        if (provinceId && districtId && communeId) {
            const cites = await territorialUnitsRequestService.getList(`${Endpoints.Cities}/${provinceId}/${districtId}/${communeId}`);
            setCities(cites);
        }
    };

    const loadStreets = async (provinceId: string | number, districtId: string | number, communeId: string | number, cityId: string | number) => {
        if (provinceId && districtId && communeId && cityId) {
            const streets = await territorialUnitsRequestService.getList(`${Endpoints.Streets}/${provinceId}/${districtId}/${communeId}/${cityId}`);
            setStreets(streets);
        }
    };

    const handleOnStreetChangeValue =
        (formik: FormikProps<INewPerson>, fieldName: string) =>
        (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number, value?: string) => {
            if (fieldName && option) {
                formik.setFieldValue(fieldName, option?.key);
            } else if (fieldName && value) {
                streetsOptions.push({ key: value, text: value });
                streetsOptions.sort((a, b) => a.text.localeCompare(b.text));
                formik.setFieldValue(fieldName, value);
            }
        };

    const clearValues = (fieldNames: string[]) => {
        fieldNames.forEach((fieldName) => {
            formik.setFieldValue(parentObjectName ? `${parentObjectName}.${fieldName}` : fieldName, '');
        });
    };

    const isDisabled = () =>
        parentObjectName
            ? isPoland
                ? formik.values[parentObjectName][City] == ''
                : formik.values[parentObjectName][CountryId] == ''
            : isPoland
            ? formik.values[City] == ''
            : formik.values[CountryId] == '';

    return (
        <div className={styles.addressWrapper}>
            <h2>{title}</h2>
            <div className={styles.formsControlsWrapper}>
                <div className={styles.territorialUnitsWrapper}>
                    {displayCountry && (
                        <div>
                            <ComboBox
                                label={t('AddressLabels.Country')}
                                className={styles.comboBox}
                                options={countryOptions}
                                required
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${CountryId}` : CountryId)}
                                onChange={handleOnComboChangeValue(formik, parentObjectName ? `${parentObjectName}.${CountryId}` : CountryId)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][CountryId] : formik.values[CountryId]}
                                allowFreeform={true}
                                onBlur={() =>
                                    clearValues([Province, District, Commune, City, PostCode, RegistrationPostOfficeCity, Street, HouseNumber, FlatNumber])
                                }
                            />
                        </div>
                    )}
                    {isPoland ? (
                        <div>
                            <ComboBox
                                label={t('AddressLabels.Province')}
                                className={styles.comboBox}
                                options={provincesOptions}
                                required
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${Province}` : Province)}
                                onChange={handleOnComboChangeValue(formik, parentObjectName ? `${parentObjectName}.${Province}` : Province)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][Province] : formik.values[Province]}
                                allowFreeform={true}
                                onBlur={() => clearValues([District, Commune, City, PostCode, RegistrationPostOfficeCity, Street, HouseNumber, FlatNumber])}
                            />
                        </div>
                    ) : (
                        <TooltipHost
                            content={
                                parentObjectName
                                    ? !formik.values[parentObjectName][CountryId]
                                        ? t('Tooltip.FillIn', {
                                              fieldName: t('AddressLabels.Country')
                                          })
                                        : ''
                                    : !formik.values[CountryId]
                                    ? t('Tooltip.FillIn', {
                                          fieldName: t('AddressLabels.Country')
                                      })
                                    : ''
                            }
                            id={provinceTooltipId}
                        >
                            <TextField
                                label={t('AddressLabels.Province')}
                                required
                                disabled={parentObjectName ? formik.values[parentObjectName][CountryId] == '' : formik.values[CountryId] == ''}
                                {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Province}` : Province)}
                            />
                        </TooltipHost>
                    )}
                    {isPoland && (
                        <TooltipHost
                            content={
                                parentObjectName
                                    ? !formik.values[parentObjectName][Province]
                                        ? t('Tooltip.FillIn', {
                                              fieldName: t('AddressLabels.Province')
                                          })
                                        : ''
                                    : !formik.values[Province]
                                    ? t('Tooltip.FillIn', {
                                          fieldName: t('AddressLabels.Province')
                                      })
                                    : ''
                            }
                            id={districtTooltipId}
                        >
                            <ComboBox
                                label={t('AddressLabels.District')}
                                className={`${styles.comboBox}
                             ${(parentObjectName ? !formik.values[parentObjectName][Province] : !formik.values[Province]) ? styles.comboDisabled : ''}`}
                                disabled={parentObjectName ? !formik.values[parentObjectName][Province] : !formik.values[Province]}
                                options={districtsOptions}
                                required
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${District}` : District)}
                                onChange={handleOnComboChangeValue(formik, parentObjectName ? `${parentObjectName}.${District}` : District)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][District] : formik.values[District]}
                                allowFreeform={true}
                                onBlur={() => clearValues([Commune, City, PostCode, RegistrationPostOfficeCity, Street, HouseNumber, FlatNumber])}
                            />
                        </TooltipHost>
                    )}
                    {isPoland && (
                        <TooltipHost
                            content={
                                parentObjectName
                                    ? !formik.values[parentObjectName][District]
                                        ? t('Tooltip.FillIn', {
                                              fieldName: t('AddressLabels.District')
                                          })
                                        : ''
                                    : !formik.values[District]
                                    ? t('Tooltip.FillIn', {
                                          fieldName: t('AddressLabels.District')
                                      })
                                    : ''
                            }
                            id={communeTooltipId}
                        >
                            <ComboBox
                                label={t('AddressLabels.Commune')}
                                className={`${styles.comboBox}
                             ${(parentObjectName ? !formik.values[parentObjectName][District] : !formik.values[District]) ? styles.comboDisabled : ''}`}
                                disabled={parentObjectName ? !formik.values[parentObjectName][District] : !formik.values[District]}
                                options={communesOptions}
                                required
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${Commune}` : Commune)}
                                onChange={handleOnComboChangeValue(formik, parentObjectName ? `${parentObjectName}.${Commune}` : Commune)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][Commune] : formik.values[Commune]}
                                allowFreeform={true}
                                onBlur={() => clearValues([City, PostCode, RegistrationPostOfficeCity, Street, HouseNumber, FlatNumber])}
                            />
                        </TooltipHost>
                    )}
                    {isPoland ? (
                        <TooltipHost
                            content={
                                parentObjectName
                                    ? !formik.values[parentObjectName][Commune]
                                        ? t('Tooltip.FillIn', {
                                              fieldName: t('AddressLabels.Commune')
                                          })
                                        : ''
                                    : !formik.values[Commune]
                                    ? t('Tooltip.FillIn', {
                                          fieldName: t('AddressLabels.Commune')
                                      })
                                    : ''
                            }
                            id={cityComboTooltipId}
                        >
                            <ComboBox
                                label={t('AddressLabels.City')}
                                className={`${styles.comboBox}
                             ${(parentObjectName ? !formik.values[parentObjectName][Commune] : !formik.values[Commune]) ? styles.comboDisabled : ''}`}
                                disabled={parentObjectName ? !formik.values[parentObjectName][Commune] : !formik.values[Commune]}
                                options={citiesOptions}
                                required
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${City}` : City)}
                                onChange={handleOnComboChangeValue(formik, parentObjectName ? `${parentObjectName}.${City}` : City)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][City] : formik.values[City]}
                                allowFreeform={true}
                                onBlur={() => clearValues([Street, PostCode, RegistrationPostOfficeCity, HouseNumber, FlatNumber])}
                            />
                        </TooltipHost>
                    ) : (
                        <TooltipHost
                            content={
                                parentObjectName
                                    ? !formik.values[parentObjectName][CountryId]
                                        ? t('Tooltip.FillIn', {
                                              fieldName: t('AddressLabels.Country')
                                          })
                                        : ''
                                    : !formik.values[CountryId]
                                    ? t('Tooltip.FillIn', {
                                          fieldName: t('AddressLabels.Country')
                                      })
                                    : ''
                            }
                            id={cityTextTooltipId}
                        >
                            <TextField
                                label={t('AddressLabels.City')}
                                disabled={parentObjectName ? formik.values[parentObjectName][CountryId] == '' : formik.values[CountryId] == ''}
                                required
                                {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${City}` : City)}
                            />
                        </TooltipHost>
                    )}
                </div>
                <div className={styles.postWrapper}>
                    <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={postCodeTooltipId}>
                        <TextField
                            label={t('AddressLabels.PostCode')}
                            required
                            disabled={isDisabled()}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${PostCode}` : PostCode)}
                        />
                    </TooltipHost>
                    <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={postOfficeCityTooltipId}>
                        <TextField
                            label={t('AddressLabels.PostOfficeCity')}
                            disabled={isDisabled()}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${RegistrationPostOfficeCity}` : RegistrationPostOfficeCity)}
                        />
                    </TooltipHost>
                </div>
                <div className={styles.locationWrapper}>
                    {isPoland ? (
                        <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={streetComboTooltipId}>
                            <ComboBox
                                label={t('AddressLabels.Street')}
                                className={`${styles.comboBox} ${styles.street}
                                 ${(parentObjectName ? !formik.values[parentObjectName][City] : !formik.values[City]) ? styles.comboDisabled : ''}`}
                                disabled={parentObjectName ? !formik.values[parentObjectName][City] : !formik.values[City]}
                                options={streetsOptions}
                                required={streetsOptions?.length > 0}
                                {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${Street}` : Street)}
                                onChange={handleOnStreetChangeValue(formik, parentObjectName ? `${parentObjectName}.${Street}` : Street)}
                                selectedKey={parentObjectName ? formik.values[parentObjectName][Street] : formik.values[Street]}
                                allowFreeform={true}
                            />
                        </TooltipHost>
                    ) : (
                        <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={streetTextTooltipId}>
                            <TextField
                                label={t('AddressLabels.Street')}
                                className={styles.street}
                                disabled={parentObjectName ? formik.values[parentObjectName][CountryId] == '' : formik.values[CountryId] == ''}
                                {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Street}` : Street)}
                            />
                        </TooltipHost>
                    )}
                    <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={houseNumberTooltipId}>
                        <TextField
                            label={t('AddressLabels.HouseNumber')}
                            className={styles.houseNumber}
                            required
                            disabled={isDisabled()}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${HouseNumber}` : HouseNumber)}
                        />
                    </TooltipHost>
                    <TooltipHost content={isDisabled() ? t('Tooltip.FillIn', { fieldName: t('AddressLabels.City') }) : ''} id={flatNumberTooltipId}>
                        <TextField
                            label={t('AddressLabels.FlatNumber')}
                            className={styles.flatNumber}
                            disabled={isDisabled()}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${FlatNumber}` : FlatNumber)}
                        />
                    </TooltipHost>
                </div>
            </div>
        </div>
    );
};

export default AdressForm;
