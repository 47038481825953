import { ProvincesState, ProvincesAction, SET_PROVINCES } from '../actionTypes/provincesTypes';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';

const localStorageProvinces = localStorage.getItem(LocalStorageNames.provinces);

const initialState: ProvincesState = {
    provinces: localStorageProvinces ? JSON.parse(localStorageProvinces) : []
};

export const provincesReducer = (state = initialState, action: ProvincesAction): ProvincesState => {
    switch (action.type) {
        case SET_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            };
        default:
            return state;
    }
};

export default provincesReducer;
