import { Checkbox, ComboBox, IComboBox, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { FormikProps } from 'formik';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { PolandId } from './../../constants/constants';
import styles from './CompanyForm.module.scss';

interface ICompanyFormProps {
    formik: FormikProps<INewPerson>;
    getFieldProps: (formik, field) => void;
    getErrorFieldProps: (formik, field) => void | { errorMessage: string };
    countryOptions: IDropdownOption[];
    handleOnCountryChange: (formik, fieldName) =>
        (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string) => void; parentObjectName?: string;
    handleOnNipChange: (formik, fieldName) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    addressObjectName?: string;
    title?: string;
    hasCompanyDetails: boolean;
}

const Name = "name";
const CountryId = "countryId";
const VatTaxPayer = "vatTaxPayer";
const Address = "address";
const PostCode = "postCode";
const City = "city";
const Nip = "nip";
const Regon = "regon";
const EuVatNumber = "euVatNumber";

const CompanyForm = ({ formik,
    getFieldProps,
    getErrorFieldProps,
    countryOptions,
    handleOnCountryChange,
    handleOnNipChange,
    parentObjectName,
    addressObjectName,
    title,
    hasCompanyDetails }: ICompanyFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [isVatTaxPayer, setIsVatTaxPayer] = useState<boolean>()
    const [countryId, setCountryId] = useState<number>();

    useEffect(() => {
        setIsVatTaxPayer(parentObjectName ? formik.values[parentObjectName][VatTaxPayer] : formik.values[VatTaxPayer]);
    }, [parentObjectName ? formik.values[parentObjectName][VatTaxPayer] : formik.values[VatTaxPayer]])

    useEffect(() => {
        setCountryId(parentObjectName ? formik.values[parentObjectName][CountryId] : formik.values[CountryId]);
    }, [parentObjectName ? formik.values[parentObjectName][CountryId] : formik.values[CountryId]])

    const isDisabled = () => parentObjectName ?
        ((formik.values[parentObjectName][CountryId] == "" || formik.values[parentObjectName][CountryId] == PolandId) && !hasCompanyDetails) :
        ((formik.values[CountryId] == "" || formik.values[CountryId] == PolandId) && !hasCompanyDetails)

    return (
        <div className={styles.employedOnB2BDetailsWrapper}>
            <h2>{title}</h2>
            <div className={styles.formsControlsWrapper}>
                <ComboBox label={t("CompanyLabel.Country")}
                    options={countryOptions}
                    className={styles.country}
                    required
                    {...getErrorFieldProps(formik, addressObjectName ? `${addressObjectName}.${CountryId}` : CountryId)}
                    onChange={handleOnCountryChange(formik, addressObjectName ? `${addressObjectName}.${CountryId}` : CountryId)}
                    selectedKey={addressObjectName ? formik.values[addressObjectName][CountryId] : formik.values[CountryId]}
                    allowFreeform={true}
                />
                <TextField
                    label={t("CompanyLabel.Nip")}
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Nip}` : Nip)}
                    disabled={parentObjectName ? (formik.values[parentObjectName][CountryId] == "") : (formik.values[CountryId] == "")}
                    description={t("CompanyDescription.NipNumber")}
                    onChange={handleOnNipChange(formik, parentObjectName ? `${parentObjectName}.${Nip}` : Nip)} />
                <TextField
                    label={t("CompanyLabel.Name")}
                    className={styles.companyName}
                    required
                    disabled={isDisabled()}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Name}` : Name)} />
                <TextField
                    label={t("CompanyLabel.Regon")}
                    required
                    disabled={isDisabled()}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Regon}` : Regon)}
                    description={t("CompanyDescription.Regon")} />
                <TextField
                    label={t("CompanyLabel.Address")}
                    className={styles.address}
                    required
                    disabled={isDisabled()}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Address}` : Address)} />
                <div className={styles.postWrapper} >
                    <TextField
                        label={t("CompanyLabel.PostCode")}
                        className={styles.postCode}
                        required
                        disabled={isDisabled()}
                        {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${PostCode}` : PostCode)} />
                    <TextField
                        label={t("CompanyLabel.City")}
                        className={styles.postOfficeCity}
                        required
                        disabled={isDisabled()}
                        {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${City}` : City)} />
                </div>
                <div className={styles.checkoboxField}>
                    <Checkbox
                        label={t("CompanyLabel.VatTaxPayer")}
                        className={styles.vatTaxPayer}
                        required
                        {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${VatTaxPayer}` : VatTaxPayer)}
                        checked={parentObjectName ? formik.values[parentObjectName][VatTaxPayer] : formik.values[VatTaxPayer]} />
                </div>
                {isVatTaxPayer && countryId != PolandId && <div className={styles.euVatNumberWrapper}>
                    <TextField
                        label={t("CompanyLabel.EuVatNumber")}
                        className={styles.euVatNumber}
                        {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${EuVatNumber}` : EuVatNumber)}
                        description={t("CompanyDescription.EuVatNumber")} />
                </div>}
            </div>
        </div>)
}

export default CompanyForm;