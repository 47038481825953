import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { CountriesAction, CountriesState, SET_COUNTRIES } from '../actionTypes/countriesTypes';

const localStorageCountries = localStorage.getItem(LocalStorageNames.countries);

const initialState: CountriesState = {
    countries: localStorageCountries ? JSON.parse(localStorageCountries) : []
};

export const countriesReducer = (state = initialState, action: CountriesAction): CountriesState => {
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        default:
            return state;
    }
};

export default countriesReducer;
