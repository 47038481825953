export const Endpoints = {
    Countries: 'api/Countries',
    Provinces: 'api/Provinces',
    Districts: 'api/Districts',
    Communes: 'api/Communes',
    Cities: 'api/Cities',
    Streets: 'api/Streets',
    NewPersonForm: 'api/NewPersonForm',
    TaxOffices: 'api/TaxOffices',
    CompanyDetails: 'api/CompanyDetails'
};
