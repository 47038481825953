export class Address {
    street = '';
    houseNumber = '';
    flatNumber = '';
    postCode = '';
    registrationPostOfficeCity = '';
    city = '';
    commune = '';
    district = '';
    province = '';
    countryId = 0;
}
