import { defaultDatePickerStrings, IDatePickerStrings } from '@fluentui/react/lib/components/DatePicker';

export const PolandId = 2;
export const MinimumAge = 16;
export const DatePickerStrings = {
    pl: {
        months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        shortMonths: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
        days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        shortDays: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
        goToToday: 'Przejdź do dzisiaj',
        prevMonthAriaLabel: 'Przejdź do poprzedniego miesiąca',
        nextMonthAriaLabel: 'Przejdź do następnego miesiąca',
        prevYearAriaLabel: 'Przejdź do poprzedniego roku',
        nextYearAriaLabel: 'Przejdź do następnego roku'
    } as IDatePickerStrings,
    en: defaultDatePickerStrings as IDatePickerStrings
};

export const SigningContractType = {
    OwnQualifiedSignature: 'Przy użyciu własnego podpisu kwalifikowanego',
    InPersonAtTheOffice: 'Podpis odręczny – osobiście w biurze',
    OwnCourierDelivery: 'Podpis odręczny – samodzielna wysyłka kurierska',
    ContractDeliveredByCourier: 'Podpis odręczny – umowę dostarczy kurier'
};
