import { AnchorNavigationAction, AnchorNavigationState, SET_ANACHOR_NAVIGATION } from '../actionTypes/anchorNavigationTypes';

const intialValue: AnchorNavigationState = {
    anchorNavigation: []
};

export const anchorNavigationReducer = (state = intialValue, action: AnchorNavigationAction): AnchorNavigationState => {
    switch (action.type) {
        case SET_ANACHOR_NAVIGATION:
            return {
                ...state,
                anchorNavigation: action.payload
            };
        default:
            return state;
    }
};

export default anchorNavigationReducer;
