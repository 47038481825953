import React from "react"
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { NotificationType } from '../../models/enums/NotificationType';
import { setNotification } from "../../redux/actions/notificationActions";
import styles from './Notification.module.scss';

const Notification = (): JSX.Element => {
    const { notification } = useSelector((state: RootState) => state.notification);
    const dispatch = useDispatch();

    const hideNotification = () => {
        dispatch(setNotification({
            type: NotificationType.none,
            message: ""
        }));
    }

    return (
        <div className={styles.notificationOuterWrapper}>
            {notification.type === NotificationType.success &&
                <div className={styles.notificationWrapper}>
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={true}
                        onDismiss={hideNotification}
                        dismissButtonAriaLabel="Close">
                        {notification.message}
                    </MessageBar>
                </div>}
            {notification.type === NotificationType.error &&
                <div className={styles.notificationWrapper}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        onDismiss={hideNotification}
                        dismissButtonAriaLabel="Close">
                        {notification.message}
                    </MessageBar>
                </div>}
        </div>
    )
}

export default Notification;