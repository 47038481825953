import { LanguageAbbreviation } from '../../models/enums/LanguageAbbreviation';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface LangState {
    language: LanguageAbbreviation;
}

interface SetLanguageAction {
    type: typeof SET_LANGUAGE;
    payload: LanguageAbbreviation;
}

export type LangAction = SetLanguageAction;
