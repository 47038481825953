import { SET_NEW_PERSON_FORM, NewPersonFormAction, NewPersonFormState } from '../actionTypes/newPersonFormTypes';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { INewPerson } from '../../models/interfaces/INewPerson';

const localStorageNewPersonForm = localStorage.getItem(LocalStorageNames.newPersonForm);

const initialState: NewPersonFormState = {
    newPersonForm: localStorageNewPersonForm ? (JSON.parse(localStorageNewPersonForm) as INewPerson) : ''
};

const newPersonFormReducer = (state = initialState, action: NewPersonFormAction): NewPersonFormState => {
    switch (action.type) {
        case SET_NEW_PERSON_FORM:
            return {
                ...state,
                newPersonForm: action.payload
            };
        default:
            return state;
    }
};

export default newPersonFormReducer;
