import { INewPerson } from './../../models/interfaces/INewPerson';
export const SET_NEW_PERSON_FORM = 'SET_NEW_PERSON_FORM';

export interface NewPersonFormState {
    newPersonForm: INewPerson | '';
}

interface SetNewPersonFormAction {
    type: typeof SET_NEW_PERSON_FORM;
    payload: INewPerson;
}

export type NewPersonFormAction = SetNewPersonFormAction;
