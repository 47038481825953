import { ICountry } from '../../models/interfaces/ICountry';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export interface CountriesState {
    countries: ICountry[];
}

interface SetCountries {
    type: typeof SET_COUNTRIES;
    payload: ICountry[];
}

export type CountriesAction = SetCountries;
