import { ITerritorialUnit } from '../../models/interfaces/ITerritorialUnit';
import { ProvincesAction, SET_PROVINCES } from '../actionTypes/provincesTypes';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';

export const setProvinces = (provinces: ITerritorialUnit[]): ProvincesAction => {
    localStorage.setItem(LocalStorageNames.provinces, JSON.stringify(provinces));

    return {
        type: SET_PROVINCES,
        payload: provinces
    };
};
