import { IAnchorNavigation } from '../../models/interfaces/IAnchorNavigation';

export const SET_ANACHOR_NAVIGATION = 'SET_ANACHOR_NAVIGATION';

export interface AnchorNavigationState {
    anchorNavigation: IAnchorNavigation[];
}

interface SetAnchorNavigationAction {
    type: typeof SET_ANACHOR_NAVIGATION;
    payload: IAnchorNavigation[];
}

export type AnchorNavigationAction = SetAnchorNavigationAction;
