import { Checkbox, IChoiceGroupOption, IComboBox, IComboBoxOption, IDropdownOption, Spinner, SpinnerSize } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { FieldInputProps, Formik, FormikProps } from 'formik';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validatePolish } from 'validate-polish';
import * as yup from 'yup';
import AdressForm from '../../components/addressForm/AddressForm';
import CompanyForm from '../../components/companyForm/CompanyForm';
import EmploymentDetailsForm from '../../components/employmentDetailsForm/EmploymentDetailsForm';
import Notification from '../../components/notification/Notification';
import PersonalDetailsForm from '../../components/personalDetailsForm/PersonalDetailsForm';
import PhoneNumbersForm from '../../components/phoneNumbersForm/PhoneNumbersForm';
import { Address } from '../../models/Address';
import { Company } from '../../models/Company';
import { LanguageAbbreviation } from '../../models/enums/LanguageAbbreviation';
import { NotificationType } from '../../models/enums/NotificationType';
import { ICompanyDetails } from '../../models/interfaces/ICompanyDetails';
import { IException } from '../../models/interfaces/IException';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { NewPerson } from '../../models/NewPerson';
import { RootState } from '../../redux';
import { setAnchorNavigation } from '../../redux/actions/anchorNavigationActions';
import { setNotification } from '../../redux/actions/notificationActions';
import { Endpoints } from '../../services/const.routes';
import { DatePickerStrings, MinimumAge, PolandId } from './../../constants/constants';
import { setNewPersonForm } from './../../redux/actions/newPersonFormActions';
import { RequestService } from './../../services/RequestService';
import styles from './NewPersonForm.module.scss';
import SigningContractForm from '../../components/signingContractForm/SigningContractForm';

const newPersonRequestService = new RequestService<INewPerson>();
const companyDetailsService = new RequestService<ICompanyDetails>();

const PeselFormat = 'peselFormat';
const NipFormat = 'nipFormat';
const NIP = 'NIP';
const REGON = 'REGON';
const PESEL = 'PESEL';
const IBAN = 'IBAN';
const RegonFormat = 'regonFormat';
const IdentityCardFormat = 'identityCardFormat';
const IsMailAddress = 'isMailAddress';
const CompanyName = 'company';
const AddressName = 'address';
const MailAddressName = 'mailAddress';
const SigningContractName = 'agreementDeliveryType';
const IsCompany = 'isCompany';
const IBANRegex = /^([A-Z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$/;
const PhoneRegex = /^[0-9\\ \\+]{9,16}$/;
const PostCodeReges = /^[0-9]{2}-[0-9]{3}$/;
const PostCodeCorrectFormat = 'XX-XXX';
const CountryId = 'countryId';
const Touched = 'touched';
const Errors = 'errors';
const Divider = ' - ';
const PersonalDetailsFormId = 'PersonalDetailsForm';
const RegisteredAddressId = 'RegisteredAddress';
const MailingAddressId = 'MailingAddress';
const EmploymentDetailsFormId = 'EmploymentDetailsForm';
const MailingAddressB2BId = 'MailingAddressB2B';
const SigningContractId = 'agreementDeliveryType';

const NewPersonForm = (): JSX.Element => {
    const [newPerson, setNewPerson] = useState<NewPerson>(new NewPerson());
    const [isSending, setIsSending] = useState<boolean>(false);
    const [formWasSaved, setFormWasSaved] = useState<boolean>(false);
    const [hasCompanyDetails, setHasCompanyDetails] = useState<boolean>(false);
    const [countryOptions, setCountryOptions] = useState<IDropdownOption[]>([]);
    const notificationRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const { newPersonForm } = useSelector((state: RootState) => state.newPersonForm);
    const { userGuid } = useSelector((state: RootState) => state.userGuid);
    const { countries } = useSelector((state: RootState) => state.countries);
    const { language } = useSelector((state: RootState) => state.lang);

    const { t } = useTranslation();

    const loadCountries = async () => {
        const countires: IDropdownOption[] = countries.map((element) => {
            if (language == LanguageAbbreviation.pl) {
                return {
                    key: element.id,
                    text: element.polishName ? element.polishName : element.name
                };
            }

            return { key: element.id, text: element.name };
        });

        setCountryOptions(countryOptions.concat(countires).sort((a, b) => a.text.localeCompare(b.text)));
    };

    useEffect(() => {
        const navigationUoP = [
            { name: t('Title.PersonalDetails'), anchor: PersonalDetailsFormId },
            { name: t('Title.RegisteredAddress'), anchor: RegisteredAddressId },
            { name: t('Title.SigningContract'), anchor: SigningContractId }
        ];
        if ((newPersonForm as INewPerson)?.isMailAddress) {
            navigationUoP.push({
                name: t('Title.MailingAddress'),
                anchor: MailingAddressId
            });
        }
        navigationUoP.push({
            name: t('Title.EmploymentDetails'),
            anchor: EmploymentDetailsFormId
        });

        const nagigationB2B = [
            { name: t('Title.PersonalDetails'), anchor: PersonalDetailsFormId },
            { name: t('Title.SigningContract'), anchor: SigningContractId },
            { name: t('Title.MailingAddressB2B'), anchor: MailingAddressB2BId },
            { name: t('Title.EmploymentDetails'), anchor: EmploymentDetailsFormId }
        ];

        dispatch(setAnchorNavigation((newPersonForm as INewPerson)?.isCompany ? nagigationB2B : navigationUoP));
    }, [(newPersonForm as INewPerson)?.isMailAddress, (newPersonForm as INewPerson)?.isCompany]);

    useEffect(() => {
        if (newPersonForm) {
            if (newPersonForm.birthday) {
                newPersonForm.birthday = new Date(newPersonForm.birthday);
            }
            if (newPersonForm.address.countryId == 0 && language == LanguageAbbreviation.pl) {
                if (newPersonForm.isCompany) {
                    newPersonForm.mailAddress.countryId = PolandId;
                } else {
                    newPersonForm.address.countryId = PolandId;
                }
            }

            if (newPersonForm.isCompany && newPersonForm.company.countryId == 0 && language == LanguageAbbreviation.pl) {
                newPersonForm.company.countryId = PolandId;
            }

            setNewPerson(newPersonForm);
        }
        loadCountries();
    }, []);

    yup.addMethod(yup.string, PeselFormat, (errorMessage: string) =>
        yup.string().test(PeselFormat, errorMessage, (pesel) => (pesel ? validatePolish.pesel(pesel as string) : true))
    );

    yup.addMethod(yup.string, NipFormat, (errorMessage: string) =>
        yup.string().test(NipFormat, errorMessage, (nip) => (nip ? validatePolish.nip(nip as string) : true))
    );

    yup.addMethod(yup.string, RegonFormat, (errorMessage: string) =>
        yup.string().test(RegonFormat, errorMessage, (regon) => (regon ? validatePolish.regon(regon as string) : true))
    );

    yup.addMethod(yup.string, IdentityCardFormat, (errorMessage: string) =>
        yup.string().test(IdentityCardFormat, errorMessage, (identityCard) => (identityCard ? validatePolish.identityCard(identityCard as string) : true))
    );

    const addressSchema = yup.object().shape({
        street: yup.string(),
        houseNumber: yup.string().required(t('FormErrorMessages.Required')),
        flatNumber: yup.string(),
        postCode: yup
            .string()
            .required(t('FormErrorMessages.Required'))
            .when(CountryId, {
                is: PolandId,
                then: yup.string().matches(
                    PostCodeReges,
                    t('FormErrorMessages.WrongFormat', {
                        correctFormat: PostCodeCorrectFormat
                    })
                )
            }),
        registrationPostOfficeCity: yup.string(),
        city: yup.string().required(t('FormErrorMessages.Required')),
        commune: yup.string().when(CountryId, {
            is: PolandId,
            then: yup.string().required(t('FormErrorMessages.Required'))
        }),
        district: yup.string().when(CountryId, {
            is: PolandId,
            then: yup.string().required(t('FormErrorMessages.Required'))
        }),
        province: yup.string().required(t('FormErrorMessages.Required')),
        countryId: yup.number().positive(t('FormErrorMessages.Required'))
    });

    const companyScheme = yup.object().shape({
        name: yup.string().required(t('FormErrorMessages.Required')),
        countryId: yup.number().positive(t('FormErrorMessages.Required')),
        address: yup.string().required(t('FormErrorMessages.Required')),
        postCode: yup
            .string()
            .required(t('FormErrorMessages.Required'))
            .when(CountryId, {
                is: PolandId,
                then: yup.string().matches(
                    PostCodeReges,
                    t('FormErrorMessages.WrongFormat', {
                        correctFormat: PostCodeCorrectFormat
                    })
                )
            }),
        city: yup.string().required(t('FormErrorMessages.Required')),
        nip: yup
            .string()
            .nipFormat(t('FormErrorMessages.WrongNumberFormat', { numberName: NIP }))
            .required(t('FormErrorMessages.Required')),
        regon: yup
            .string()
            .regonFormat(t('FormErrorMessages.WrongNumberFormat', { numberName: REGON }))
            .required(t('FormErrorMessages.Required')),
        vatTaxPayer: yup.bool(),
        euVatNumber: yup.string()
    });

    const newPersonFormSchema = yup.object().shape({
        firstName: yup.string().required(t('FormErrorMessages.Required')),
        secondName: yup.string(),
        lastName: yup.string().required(t('FormErrorMessages.Required')),
        domainLogin: yup.string().required(t('FormErrorMessages.Required')),
        pesel: yup.string().when(IsCompany, {
            is: false,
            then: yup.string().when('address.countryId', {
                is: PolandId,
                then: yup
                    .string()
                    .peselFormat(t('FormErrorMessages.WrongNumberFormat', { numberName: PESEL }))
                    .required(t('FormErrorMessages.Required'))
            })
        }),
        gender: yup.string().required(t('FormErrorMessages.Required')),
        birthday: yup.date().when(IsCompany, {
            is: false,
            then: yup
                .date()
                .required(t('FormErrorMessages.Required'))
                .max(new Date(new Date().setFullYear(new Date().getFullYear() - MinimumAge)), t('FormErrorMessages.MinimumAge'))
        }),
        birthPlace: yup.string().when(IsCompany, {
            is: false,
            then: yup.string().required(t('FormErrorMessages.Required'))
        }),
        familyName: yup.string(),
        identityCard: yup.string().when(IsCompany, {
            is: false,
            then: yup
                .string()
                .when('address.countryId', {
                    is: (countryId) => countryId != PolandId,
                    then: yup.string().required(t('FormErrorMessages.Required'))
                })
                .when('address.countryId', {
                    is: (countryId) => countryId == PolandId,
                    then: yup.string().identityCardFormat(t('FormErrorMessages.InvalidIdentityCard'))
                })
        }),
        identityCardIssuedBy: yup.string().when(IsCompany, {
            is: false,
            then: yup.string().when('address.countryId', {
                is: (countryId) => countryId != PolandId,
                then: yup.string().required(t('FormErrorMessages.Required'))
            })
        }),
        nip: yup.string().nipFormat(t('FormErrorMessages.WrongNumberFormat', { numberName: NIP })),
        emergencyPhoneNumber: yup.string().matches(PhoneRegex, t('FormErrorMessages.InvalidPhoneNumber')),
        bankAccountNumber: yup
            .string()
            .required(t('FormErrorMessages.Required'))
            .matches(IBANRegex, t('FormErrorMessages.WrongNumberFormat', { numberName: IBAN })),
        taxOffice: yup.string().when(IsCompany, {
            is: false,
            then: yup.string().required(t('FormErrorMessages.Required'))
        }),
        disability: yup.bool(),
        deliverAgreementByCourier: yup.bool(),
        agreementDeliveryType: yup.string().required(t('FormErrorMessages.Required')),
        hasCollege: yup.bool(),
        address: yup.object().when(IsCompany, { is: false, then: addressSchema }),
        isCompany: yup.bool(),
        isMailAddress: yup.bool(),
        mailAddress: yup.object().when(IsCompany, { is: true, then: addressSchema }).when(IsMailAddress, { is: true, then: addressSchema }),
        company: yup.object().when(IsCompany, { is: true, then: companyScheme })
    });

    const getNestedFieldProps = (formik: FormikProps<INewPerson>, field: string): FieldInputProps<INewPerson> & { errorMessage: string } => {
        return {
            ...formik.getFieldProps(field),
            errorMessage:
                getNestedProperty(formik, [Touched].concat(field.split('.'))) && (getNestedProperty(formik, [Errors].concat(field.split('.'))) as string)
        };
    };

    const getErrorFieldProps = (formik: FormikProps<INewPerson>, field: string): { errorMessage: string } => {
        return {
            errorMessage:
                getNestedProperty(formik, [Touched].concat(field.split('.'))) && (getNestedProperty(formik, [Errors].concat(field.split('.'))) as string)
        };
    };

    const getFieldProps = (formik: FormikProps<INewPerson>, field: string): FieldInputProps<INewPerson> & { errorMessage: string } => {
        return {
            ...formik.getFieldProps(field),
            errorMessage: formik.touched[field] && (formik.errors[field] as string)
        };
    };

    const handleOnChangeValue =
        (formik: FormikProps<INewPerson>, fieldName: string) => (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
            if (fieldName) {
                formik.setFieldValue(fieldName, option?.key);
            }
        };

    const handleGroupOptionOnChangeValue =
        (formik: FormikProps<INewPerson>, fieldName: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
            if (fieldName) {
                formik.setFieldValue(fieldName, option?.key);
            }
        };

    const handleOnComboChangeValue =
        (formik: FormikProps<INewPerson>, fieldName: string) => (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined) => {
            if (fieldName && option) {
                formik.setFieldValue(fieldName, option?.key);
            }
        };

    const handleOnSelectDate = (formik: FormikProps<INewPerson>, fieldName: string) => async (date: Date | null | undefined) => {
        if (fieldName) {
            await formik.setFieldValue(fieldName, date);
            await formik.setFieldTouched(fieldName, true);
        }
    };

    const handleOnCheckboxChange = (formik: FormikProps<INewPerson>, checkboxName, checkboxValue) => {
        formik.setFieldValue(checkboxName, checkboxValue);
    };

    const handleOnNipChange =
        (formik: FormikProps<INewPerson>, fieldName: string) =>
        async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            await formik.setFieldValue(fieldName, newValue);

            if (!newValue || !validatePolish.nip(newValue as string)) {
                resetCompanyForm(formik);

                return;
            }

            if (formik.values.company[CountryId] != PolandId) {
                return;
            }

            try {
                const companyDetails = await companyDetailsService.get(`${Endpoints.CompanyDetails}/${newValue}`);

                // eslint-disable-next-line max-len
                const companyAddress = `${companyDetails?.street} ${companyDetails?.houseNumber}${
                    companyDetails?.flatNumber ? `/${companyDetails?.flatNumber}` : ''
                }`;
                formik.setFieldValue('company.regon', companyDetails?.regon);
                formik.setFieldValue('company.name', companyDetails?.name);
                formik.setFieldValue('company.postCode', companyDetails?.postCode);
                formik.setFieldValue('company.city', companyDetails?.city);
                formik.setFieldValue('company.address', companyAddress);
                setHasCompanyDetails(true);
            } catch (error) {
                const errorMessage = (error as IException)?.status === 503 ? t('Messages.BirUnavailable') : t('Messages.BirError');

                dispatch(
                    setNotification({
                        type: NotificationType.error,
                        message: errorMessage
                    })
                );
                resetCompanyForm(formik);
                scrollToNotification();
            }
        };

    const scrollToNotification = () => {
        notificationRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const resetCompanyForm = (formik: FormikProps<INewPerson>) => {
        if (
            formik.values.company.countryId == PolandId &&
            (formik.values.company.regon ||
                formik.values.company.name ||
                formik.values.company.postCode ||
                formik.values.company.address ||
                formik.values.company.city)
        ) {
            formik.setFieldValue('company.regon', '');
            formik.setFieldValue('company.name', '');
            formik.setFieldValue('company.postCode', '');
            formik.setFieldValue('company.city', '');
            formik.setFieldValue('company.address', '');
            setHasCompanyDetails(false);
        }
    };

    const displayErrors = async (formik: FormikProps<INewPerson>) => {
        const errors = await formik.validateForm();

        if (errors && Object.keys(errors).length > 0) {
            dispatch(
                setNotification({
                    type: NotificationType.error,
                    message: t('Messages.CorrectData')
                })
            );
            window.setTimeout(scrollToNotification, 100);
        }
    };

    const processForm = async (formik: FormikProps<INewPerson>) => {
        if (!formik.values.isCompany) {
            formik.setFieldValue(CompanyName, new Company());

            if (!formik.values.isMailAddress) {
                formik.setFieldValue(MailAddressName, new Address());
            }

            if (formik.values.isMailAddress) {
                if (formik.values.mailAddress.countryId != PolandId) {
                    formik.setFieldValue('mailAddress.district', '');
                    formik.setFieldValue('mailAddress.commune', '');
                }
            }
        }

        if (formik.values.address.countryId != PolandId) {
            formik.setFieldValue('address.district', '');
            formik.setFieldValue('address.commune', '');
        }

        formik.handleSubmit();
        await displayErrors(formik);
    };

    const getTerritorialUnitName = (name: string) => {
        const territorialUnitName = name.lastIndexOf(Divider) > 0 ? name.substring(0, name.lastIndexOf(Divider)) : '';

        return territorialUnitName;
    };

    const removeTerritorialUnitTypeInAddresses = (newPerson: INewPerson) => {
        if (newPerson.address.countryId == PolandId) {
            newPerson.address.commune = getTerritorialUnitName(newPerson.address.commune);
            newPerson.address.city = getTerritorialUnitName(newPerson.address.city);
        }

        if (newPerson.mailAddress.countryId == PolandId) {
            newPerson.mailAddress.commune = getTerritorialUnitName(newPerson.mailAddress.commune);
            newPerson.mailAddress.city = getTerritorialUnitName(newPerson.mailAddress.city);
        }
    };

    const removeEmptyString = (newPerson) => {
        for (const key in newPerson) {
            if (typeof newPerson[key] == 'object') {
                removeEmptyString(newPerson[key]);
            }

            if (newPerson[key] === '') {
                newPerson[key] = null;
            }
        }
    };

    const removeTimeZones = (values: INewPerson) => {
        if (values.birthday) {
            values.birthday = ((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000))(new Date(values.birthday));
        }
    };

    const removeUnnecessaryUoPData = (values) => {
        if (values.isCompany) {
            values.hasCollege = null;
            values.disability = null;
            values.isMailAddress = null;
        }

        if (values.isCompany && values.company.countryId == PolandId) {
            values.company.euVatNumber = null;
        }
    };

    const sendForm = async (values: INewPerson): Promise<void> => {
        const purgedValues = JSON.parse(JSON.stringify(values));
        removeTerritorialUnitTypeInAddresses(purgedValues);
        removeEmptyString(purgedValues);
        removeTimeZones(purgedValues);
        removeUnnecessaryUoPData(purgedValues);

        dispatch(
            setNotification({
                type: NotificationType.none,
                message: ''
            })
        );

        try {
            setIsSending(true);
            await newPersonRequestService.post(`${Endpoints.NewPersonForm}/${userGuid}`, purgedValues);
            dispatch(
                setNotification({
                    type: NotificationType.success,
                    message: t('Messages.SavedSuccess')
                })
            );
            setFormWasSaved(true);
        } catch (exception) {
            dispatch(
                setNotification({
                    type: NotificationType.error,
                    message: t('Messages.SavedError')
                })
            );
        } finally {
            setIsSending(false);
            scrollToNotification();
        }
    };

    const purgeFromSensitiveData = (newPerson: INewPerson) => {
        const purgedValues = JSON.parse(JSON.stringify(newPerson));
        purgedValues.pesel = '';
        purgedValues.identityCard = '';
        purgedValues.identityCardIssuedBy = '';
        purgedValues.bankAccountNumber = '';
        purgedValues.nip = '';
        purgedValues.company.nip = '';
        purgedValues.company.regon = '';

        if (purgedValues.company.countryId == PolandId) {
            purgedValues.company.name = '';
            purgedValues.company.address = '';
            purgedValues.company.city = '';
            purgedValues.company.postCode = '';
        }

        return purgedValues;
    };

    return (
        <div className={styles.newPersonFormWrapper}>
            <Formik enableReinitialize={true} initialValues={newPerson} validationSchema={newPersonFormSchema} onSubmit={sendForm}>
                {(formik) => {
                    useEffect(() => {
                        if (formik.values != newPerson) {
                            dispatch(setNewPersonForm(purgeFromSensitiveData(formik.values)));
                        }
                    }, [formik.values]);

                    return (
                        <div>
                            <div id={PersonalDetailsFormId}>
                                <PersonalDetailsForm
                                    getFieldProps={getNestedFieldProps}
                                    getErrorFieldProps={getErrorFieldProps}
                                    formik={formik}
                                    handleOnGenderChange={handleOnChangeValue}
                                    handleOnSelectDate={handleOnSelectDate}
                                    handleOnCountryChange={handleOnComboChangeValue}
                                    countryOptions={countryOptions}
                                    isCompany={formik.values.isCompany}
                                    addressObjectName={formik.values.isCompany ? MailAddressName : AddressName}
                                    title={t('Title.PersonalDetails')}
                                    dataPickerStrings={DatePickerStrings[language]}
                                />
                            </div>
                            <div id={RegisteredAddressId} className={formik.values.isCompany ? styles.displayNone : styles.displayBlock}>
                                <AdressForm
                                    getFieldProps={getNestedFieldProps}
                                    getErrorFieldProps={getErrorFieldProps}
                                    formik={formik}
                                    handleOnComboChangeValue={handleOnComboChangeValue}
                                    countryOptions={countryOptions}
                                    parentObjectName={AddressName}
                                    title={t('Title.RegisteredAddress')}
                                    displayCountry={false}
                                />
                                <div className={styles.checkoboxField}>
                                    <Checkbox
                                        label={t('IsMailingAddress')}
                                        checked={!formik.values.isMailAddress}
                                        {...getFieldProps(formik, IsMailAddress)}
                                        onChange={() => {
                                            handleOnCheckboxChange(formik, IsMailAddress, !formik.values.isMailAddress);
                                        }}
                                    />
                                    <div className={styles.fieldDescription}>{t('IsMailingAddressDescription')}</div>
                                </div>
                            </div>
                            <div id={MailingAddressId} className={formik.values.isCompany ? styles.displayNone : styles.displayBlock}>
                                {formik.values.isMailAddress && (
                                    <AdressForm
                                        getFieldProps={getNestedFieldProps}
                                        getErrorFieldProps={getErrorFieldProps}
                                        formik={formik}
                                        handleOnComboChangeValue={handleOnComboChangeValue}
                                        countryOptions={countryOptions}
                                        parentObjectName={MailAddressName}
                                        title={t('Title.MailingAddress')}
                                    />
                                )}
                            </div>
                            <div id={MailingAddressB2BId} className={formik.values.isCompany ? styles.displayBlock : styles.displayNone}>
                                <AdressForm
                                    getFieldProps={getNestedFieldProps}
                                    getErrorFieldProps={getErrorFieldProps}
                                    formik={formik}
                                    handleOnComboChangeValue={handleOnComboChangeValue}
                                    countryOptions={countryOptions}
                                    parentObjectName={MailAddressName}
                                    title={t('Title.MailingAddressB2B')}
                                    displayCountry={false}
                                />
                            </div>
                            <div id={SigningContractId}>
                                <div className={styles.divider}></div>
                                <SigningContractForm
                                    formik={formik}
                                    getErrorFieldProps={getErrorFieldProps}
                                    getFieldProps={getErrorFieldProps}
                                    title={t('Title.SigningContract')}
                                    parentObjectName={SigningContractName}
                                    handleGroupOptionOnChangeValue={handleGroupOptionOnChangeValue}
                                />
                            </div>
                            <div id={EmploymentDetailsFormId}>
                                <div className={styles.divider}></div>
                                <PhoneNumbersForm getFieldProps={getNestedFieldProps} formik={formik} />
                                <EmploymentDetailsForm
                                    getErrorFieldProps={getErrorFieldProps}
                                    getFieldProps={getNestedFieldProps}
                                    formik={formik}
                                    isCompany={formik.values.isCompany}
                                    title={t('Title.EmploymentDetails')}
                                />
                                {formik.values.isCompany && (
                                    <CompanyForm
                                        getFieldProps={getNestedFieldProps}
                                        getErrorFieldProps={getErrorFieldProps}
                                        formik={formik}
                                        countryOptions={countryOptions}
                                        handleOnCountryChange={handleOnComboChangeValue}
                                        handleOnNipChange={handleOnNipChange}
                                        parentObjectName={CompanyName}
                                        addressObjectName={CompanyName}
                                        hasCompanyDetails={hasCompanyDetails}
                                    />
                                )}
                                {!formik.values.isCompany && (
                                    <div className={styles.checkoboxField}>
                                        <Checkbox
                                            label={t('HasDisabilityDegreeStatementLabel')}
                                            {...getFieldProps(formik, 'disability')}
                                            checked={formik.values.disability}
                                        />
                                    </div>
                                )}
                                {!formik.values.isCompany && (
                                    <div className={styles.checkoboxField}>
                                        <Checkbox
                                            label={t('HasStudyDegreeLabel')}
                                            {...getFieldProps(formik, 'hasCollege')}
                                            checked={formik.values.hasCollege}
                                        />
                                    </div>
                                )}
                            </div>

                            {isSending ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <div className={styles.buttonWrapper}>
                                    <PrimaryButton
                                        disabled={formWasSaved}
                                        className={styles.submitButton}
                                        type="submit"
                                        text={t('Send')}
                                        onClick={() => {
                                            processForm(formik);
                                        }}
                                    />
                                </div>
                            )}
                            <div ref={notificationRef} className={styles.notification}>
                                <Notification />
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

const getNestedProperty = (nestedItem, pathArray: string[]) =>
    pathArray.reduce((item, key) => (item && item[key] !== undefined ? item[key] : undefined), nestedItem);

export default NewPersonForm;
