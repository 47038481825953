import { LanguageAbbreviation } from '../../models/enums/LanguageAbbreviation';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { LangAction, SET_LANGUAGE } from '../actionTypes/langTypes';

export const setLanguage = (lang: LanguageAbbreviation): LangAction => {
    localStorage.setItem(LocalStorageNames.language, lang);

    return {
        type: SET_LANGUAGE,
        payload: lang
    };
};
