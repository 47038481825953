export class RequestService<T> {
    public post = async (url: string, payloads?: T): Promise<T | null> => {
        const requestHeaders: Headers = new Headers();
        requestHeaders.append('Content-type', 'application/json');

        const requestOptions = {
            body: JSON.stringify(payloads),
            method: 'POST',
            headers: requestHeaders
        };

        try {
            const response = await fetch(this.buildUrl(url), requestOptions);
            const isJson = response.headers.get('content-type')?.includes('application/json');

            if (response.ok) {
                return isJson ? await response.json() : null;
            }

            if (response.status >= 400) {
                return this.errorHandling(response);
            }

            return null;
        } catch (error) {
            this.errorHandling(error);

            return null;
        }
    };

    public get = async (url: string): Promise<T | null> => {
        const requestHeaders: Headers = new Headers();
        requestHeaders.append('Content-type', 'application/json');

        const requestOptions = {
            method: 'GET',
            headers: requestHeaders
        };

        try {
            const response = await fetch(this.buildUrl(url), requestOptions);

            if (response.status >= 400) {
                return this.errorHandling(response);
            }

            return await response.json();
        } catch (error) {
            this.errorHandling(error);

            return null;
        }
    };

    public getList = async (url: string): Promise<T[]> => {
        const requestHeaders: Headers = new Headers();
        requestHeaders.append('Content-type', 'application/json');

        const requestOptions = {
            method: 'GET',
            headers: requestHeaders
        };

        try {
            const response = await fetch(this.buildUrl(url), requestOptions);

            if (response.status >= 400) {
                return this.errorHandling(response);
            }

            return await response.json();
        } catch (error) {
            this.errorHandling(error);

            return [];
        }
    };

    private buildUrl = (endpoint: string): string => `${process.env.REACT_APP_FUNCTIONAPP_URL}/${endpoint}`;

    private errorHandling = (error) => {
        console.error(error);

        throw error;
    };
}
