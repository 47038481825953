import React, { FormEvent, useEffect, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from './EmploymentDetailsForm.module.scss';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { ITaxOffice } from '../../models/interfaces/ITaxOffice';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react/lib/components/ComboBox';
import { IDropdownOption } from '@fluentui/react/lib/components/Dropdown';
import { Endpoints } from '../../services/const.routes';
import { RequestService } from '../../services/RequestService';

interface IEmploymentDetailsFormProps {
    formik: FormikProps<INewPerson>;
    getFieldProps: (formik, field) => void;
    getErrorFieldProps: (formik, field) => void | { errorMessage: string };

    isCompany: boolean;
    parentObjectName?: string;
    title?: string;
}

const taxOfficesRequestService = new RequestService<ITaxOffice>();

const BankAccountNumber = "bankAccountNumber";
const TaxOffice = "taxOffice";

const EmploymentDetailsForm = ({ formik, getFieldProps, getErrorFieldProps, isCompany, parentObjectName, title }: IEmploymentDetailsFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [taxOfficesOptions, setTaxOfficesOptions] = useState<IDropdownOption[]>([]);
    const [taxOffices, setTaxOffices] = useState<ITaxOffice[]>([]);

    useEffect(() => {
        loadTaxOffices();

        return () => {
            setTaxOffices([]);
        }
    }, [])

    useEffect(() => {
        const taxOfficesOptions: IDropdownOption[] = taxOffices.map((taxOffice: ITaxOffice) => {
            return {
                key: taxOffice.name,
                text: taxOffice.name,
            }
        });

        const taxOfficeValue = parentObjectName ? formik.values[parentObjectName][TaxOffice] : formik.values[TaxOffice];

        if (taxOfficeValue && !taxOfficesOptions.find(taxOfficesOption => taxOfficesOption.key == taxOfficeValue)) {
            taxOfficesOptions.unshift({
                key: parentObjectName ? formik.values[parentObjectName][TaxOffice] : formik.values[TaxOffice],
                text: parentObjectName ? formik.values[parentObjectName][TaxOffice] : formik.values[TaxOffice]
            });
            taxOfficesOptions.sort((a, b) => a.text.localeCompare(b.text));
        }

        setTaxOfficesOptions(taxOfficesOptions);
    }, [taxOffices])

    const handleOnTaxOfficeChangeValue = (formik: FormikProps<INewPerson>, fieldName: string) =>
        (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number, value?: string) => {
            if (fieldName && option) {
                formik.setFieldValue(fieldName, option?.key);
            } else if (fieldName && value) {
                taxOfficesOptions.push({ key: value, text: value });
                taxOfficesOptions.sort((a, b) => a.text.localeCompare(b.text));
                formik.setFieldValue(fieldName, value);
            }
        }

    const loadTaxOffices = async () => {
        const taxOffices = await taxOfficesRequestService.getList(`${Endpoints.TaxOffices}`);
        setTaxOffices(taxOffices);
    }

    return (
        <div className={styles.employmentDetailsWrapper} >
            <h2>{title}</h2>
            <div className={styles.formsControlsWrapper}>
                <TextField
                    label={t("EmploymentDetailsLabel.BankAccountNumber")}
                    description={t("EmploymentDetailsDescription.BankAccountNumber")}
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${BankAccountNumber}` : BankAccountNumber)} />
                {!isCompany && <ComboBox
                    label={t("EmploymentDetailsLabel.TaxOffice")}
                    className={styles.comboBox}
                    options={taxOfficesOptions}
                    required
                    {...getErrorFieldProps(formik, parentObjectName ? `${parentObjectName}.${TaxOffice}` : TaxOffice)}
                    onChange={handleOnTaxOfficeChangeValue(formik, parentObjectName ? `${parentObjectName}.${TaxOffice}` : TaxOffice)}
                    selectedKey={parentObjectName ? formik.values[parentObjectName][TaxOffice] : formik.values[TaxOffice]}
                    allowFreeform={true} />}
            </div>
        </div>)
}

export default EmploymentDetailsForm;