import { createTheme } from '@fluentui/react';

export const primaryTheme = createTheme({
    palette: {
        themePrimary: '#ffa000',
        themeLighterAlt: '#fffbf5',
        themeLighter: '#fff0d6',
        themeLight: '#ffe3b3',
        themeTertiary: '#ffc766',
        themeSecondary: '#ffad1f',
        themeDarkAlt: '#e69100',
        themeDark: '#c27b00',
        themeDarker: '#8f5a00',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#cccccc',
        neutralSecondary: '#cccccc',
        neutralPrimaryAlt: '#696969',
        neutralPrimary: '#555555',
        neutralDark: '#404040',
        black: '#2f2f2f',
        white: '#ffffff'
    },
    defaultFontStyle: { fontFamily: 'Open Sans', fontWeight: 'regular' },
    fonts: {
        small: {
            fontSize: '0.75rem'
        },
        medium: {
            fontSize: '1rem'
        },
        large: {
            fontSize: '1.25rem',
            fontWeight: 'semibold'
        },
        xLarge: {
            fontSize: '1.5rem',
            fontWeight: 'semibold'
        }
    }
});
