import { Icon } from '@fluentui/react/lib/components/Icon';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n/config';
import { LanguageAbbreviation } from '../../models/enums/LanguageAbbreviation';
import { IAnchorNavigation } from '../../models/interfaces/IAnchorNavigation';
import { INavigationElementYAxis } from '../../models/interfaces/INavigationElementYAxis';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { RootState } from '../../redux';
import { setLanguage } from '../../redux/actions/langActions';
import logo from './../../assets/img/logo-fp.svg';
import styles from './MainSideBar.module.scss';

const MarginTop = 200;
const MarginBottom = 50;

const MainSideBar = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { language } = useSelector((state: RootState) => state.lang);
    const { newPersonForm } = useSelector((state: RootState) => state.newPersonForm);
    const { anchorNavigation } = useSelector((state: RootState) => state.anchorNavigation);
    const [lang, setLang] = useState<LanguageAbbreviation>(language);
    const [navigationElements, setNavigationElements] = useState<(HTMLElement | null)[]>([])
    const [navigationElementsYAxis, setNavigationElementsYAxis] = useState<(INavigationElementYAxis | undefined)[]>([])
    const [windowSize, setWindowSize] = useState([0, 0]);
    const [scrollTop, setScrollTop] = useState(0);

    const changeLanguage = (newLanguage: LanguageAbbreviation) => {
        setLang(newLanguage);
        dispatch(setLanguage(newLanguage))
        i18n.changeLanguage(newLanguage)
    }

    const goToAncher = (anchorName: string) => {
        const element = document.getElementById(`${anchorName}`);

        return () => {
            element?.scrollIntoView({ behavior: "smooth" });
        }
    }

    const isActive = (element: HTMLElement): boolean => {
        if (!element) {
            return false;
        }

        const elementTop = element?.offsetTop;
        const elemntHeight = element?.clientHeight;

        if (scrollTop >= elementTop && scrollTop < (elemntHeight + elementTop)) {
            return true;
        }

        return false;
    }

    useLayoutEffect(() => {
        const updateSize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    useLayoutEffect(() => {
        const updateScroll = () => {
            setScrollTop(window.pageYOffset + MarginTop);
        }
        window.addEventListener("scroll", updateScroll);
        updateScroll();

        return () => window.removeEventListener("scroll", updateScroll);
    }, []);

    useEffect(() => {
        const navigationElements = anchorNavigation.map(element => document.getElementById(`${element.anchor}`));
        setNavigationElements(navigationElements);
    }, [anchorNavigation])

    useEffect(() => {
        const navigationElementsYAxis = navigationElements.map(element => {
            if (element) {
                const elementTop = element?.offsetTop;
                const elemntHeight = element?.clientHeight;
                return {
                    top: elementTop,
                    bottom: (elementTop + elemntHeight),
                    active: isActive(element)
                }
            }
        })

        const parentNavigationElement = navigationElements[navigationElements.length - 1]?.parentElement;
        const lastNavigationElement = navigationElementsYAxis[navigationElements.length - 1];

        if (parentNavigationElement && (scrollTop + window.innerHeight - MarginTop - MarginBottom) >= parentNavigationElement?.scrollHeight) {
            navigationElementsYAxis.forEach(element => {
                if (element) {
                    element.active = false;
                }
            })

            if (lastNavigationElement) {
                lastNavigationElement.active = true;
            }
        }

        setNavigationElementsYAxis(navigationElementsYAxis);
    }, [navigationElements, windowSize, scrollTop])

    return (
        <div className={styles.mainSideBarWrapper}>
            <div className={styles.mainSideBarGreeting}>
                <div className={styles.fpLogo}>
                    <img src={logo} alt="fp-logo" />
                </div>
                <h3>{t("Welcome.Hello")} {(newPersonForm as INewPerson).firstName}!</h3>
                <p>
                    <Trans i18nKey="Welcome.ParagraphOne"></Trans>
                </p>
                <div className={styles.anchorNavigationWrapper}>{anchorNavigation?.map((nav: IAnchorNavigation, i) => {
                    return (<span className={navigationElementsYAxis[i]?.active ? styles.active : ""}
                        onClick={goToAncher(nav.anchor)} key={i}>{i + 1}. {nav.name}  <Icon iconName="ChevronRight" /></span>)
                })}
                </div>
            </div>
            <div className={styles.footerWrapper}>
                <span className={styles.languageTitle}>{t("Title.ChooseLanguage")}</span>
                <span className={styles.divider}></span>
                <div className={styles.languageWrapper}>
                    <p className={lang === LanguageAbbreviation.pl ? styles.active : ""} onClick={() => changeLanguage(LanguageAbbreviation.pl)}>PL</p>
                    <p className={lang === LanguageAbbreviation.en ? styles.active : ""} onClick={() => changeLanguage(LanguageAbbreviation.en)}>EN</p>
                </div>
            </div>
        </div>)
}

export default MainSideBar;