import { Address } from './Address';
import { Company } from './Company';
import { Gender } from './enums/Gender';
import { INewPerson } from './interfaces/INewPerson';

export class NewPerson implements INewPerson {
    firstName = '';
    secondName = '';
    lastName = '';
    domainLogin = '';
    pesel = '';
    gender: Gender | '' = '';
    birthday: Date | '' = '';
    birthPlace = '';
    familyName = '';
    identityCard = '';
    identityCardIssuedBy = '';
    nip = '';
    emergencyPhoneNumber = '';
    bankAccountNumber = '';
    taxOffice = '';
    disability = false;
    hasCollege = false;
    address = new Address();
    mailAddress = new Address();
    isMailAddress = false;
    isCompany = false;
    company = new Company();
    agreementDeliveryType = '';
}
