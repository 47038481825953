import {
    ComboBox, DatePicker, Dropdown, IComboBox, IComboBoxOption, IDatePickerStrings, IDropdownOption, TooltipHost
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { TextField } from '@fluentui/react/lib/TextField';
import { FormikProps } from 'formik';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gender } from '../../models/enums/Gender';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { PolandId } from './../../constants/constants';
import styles from './PersonalDetailsForm.module.scss';

interface IPersonalDetailsFormProps {
    formik: FormikProps<INewPerson>;
    getFieldProps: (formik, field) => void | { errorMessage: string };
    getErrorFieldProps: (formik, field) => void | { errorMessage: string };
    handleOnGenderChange: (formik, fieldName) => (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => void;
    handleOnCountryChange: (formik, fieldName) =>
        (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string) => void;
    handleOnSelectDate: (formik, fieldName) => (date: Date | null | undefined) => void;
    countryOptions: IDropdownOption[];
    isCompany: boolean;
    parentObjectName?: string;
    addressObjectName?: string;
    title?: string;
    dataPickerStrings?: IDatePickerStrings,
}

const CountryId = "countryId";
const Province = "province";
const District = "district";
const Commune = "commune";
const City = "city";
const PostCode = "postCode";
const RegistrationPostOfficeCity = "registrationPostOfficeCity";
const Street = "street";
const HouseNumber = "houseNumber";
const FlatNumber = "flatNumber";
const FirstName = "firstName";
const SecondName = "secondName";
const LastName = "lastName";
const DomainLogin = "domainLogin";
const Pesel = "pesel";
const GenderName = "gender";
const Birthday = "birthday";
const BirthPlace = "birthPlace";
const FamilyName = "familyName";
const IdentityCard = "identityCard";
const IdentityCardIssuedBy = "identityCardIssuedBy";
const Nip = "nip";
const PeselLength = 11;
const InitialDatePickerDate = new Date(2000, 0, 1);

const PersonalDetailsForm = ({
    formik,
    getFieldProps,
    getErrorFieldProps,
    handleOnGenderChange,
    handleOnSelectDate,
    handleOnCountryChange,
    countryOptions,
    isCompany,
    parentObjectName,
    addressObjectName,
    title,
    dataPickerStrings }: IPersonalDetailsFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [countryId, setCountryId] = useState<number>();

    // Tooltips
    const peselTooltipId = useId("peselTooltipId");
    const identityCardTooltipId = useId("identityCardTooltipId");
    const identityCardIssuedByTooltipId = useId("identityCardIssuedByTooltipId");

    const genderOptions: IDropdownOption[] = [
        { key: Gender.male, text: t('Gender.Male') },
        { key: Gender.female, text: t('Gender.Female') },
    ];

    useEffect(() => {
        setCountryId(addressObjectName ? formik.values[addressObjectName][CountryId] : formik.values[CountryId]);

    }, [addressObjectName ? formik.values[addressObjectName][CountryId] : formik.values[CountryId]])

    const formatDate = (date?: Date | undefined): string => {
        if (date) {
            return (addLeadingZero(date.getDate()) + '.' + addLeadingZero(date.getMonth() + 1) + '.' + date.getFullYear());
        }

        return "";
    }

    const addLeadingZero = (date: number) => date?.toString()?.length == 1 ? `0${date}` : date;

    const clearValues = (fieldNames: string[]) => {
        fieldNames.forEach(fieldName => {
            formik.setFieldValue(addressObjectName ? `${addressObjectName}.${fieldName}` : fieldName, "")
        });
    }

    useEffect(() => {
        const hasErrors = parentObjectName ? formik.errors[parentObjectName][Pesel] : formik.errors[Pesel];
        const value = parentObjectName ? formik.values[parentObjectName][Pesel] : formik.values[Pesel];
        const countryId = addressObjectName ? formik.values[addressObjectName][CountryId] : formik.values[CountryId];

        if (hasErrors || !value || value.length != PeselLength || countryId != PolandId) {
            return;
        }

        const lastTwoNumbersOfYear = Number(value.slice(0, 2));
        let month = Number(value.slice(2, 4));
        const day = Number(value.slice(4, 6));
        const tenNumber = Number(value.slice(9, 10));
        let fullyear;

        if (!lastTwoNumbersOfYear)

            if (isNaN(lastTwoNumbersOfYear) || isNaN(month) || isNaN(day) || isNaN(tenNumber)) {
                return;
            }

        if (month > 12) {
            month = month - 21;
            fullyear = 2000 + lastTwoNumbersOfYear;
        } else {
            month = month - 1;
            fullyear = 1900 + lastTwoNumbersOfYear;
        }

        formik.setFieldValue(parentObjectName ? `${parentObjectName}.${Birthday}` : Birthday, new Date(fullyear, month, day));
        formik.setFieldValue(parentObjectName ? `${parentObjectName}.${GenderName}` : GenderName, tenNumber % 2 === 1 ? Gender.male : Gender.female);
    }, [parentObjectName ? formik.errors[parentObjectName][Pesel] : formik.errors[Pesel],
    parentObjectName ? formik.values[parentObjectName][Pesel] : formik.values[Pesel]])

    return (
        <div className={styles.personalDetailsWrapper}>
            <h2>{title}</h2>
            <div className={styles.formsControlsWrapper}>
                <TextField
                    label={t("PersonalDetailsLabels.FirstName")}
                    disabled
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${FirstName}` : FirstName)} />
                <TextField
                    label={t("PersonalDetailsLabels.SecondName")}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${SecondName}` : SecondName)} />
                <TextField
                    label={t("PersonalDetailsLabels.LastName")}
                    disabled
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${LastName}` : LastName)} />
                <TextField
                    label={t("PersonalDetailsLabels.DomainUsername")}
                    disabled required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${DomainLogin}` : DomainLogin)} />
                <div className={styles.divider}></div>
                <ComboBox label={t("PersonalDetailsLabels.HomeCountry")}
                    {...getErrorFieldProps(formik, addressObjectName ? `${addressObjectName}.${CountryId}` : CountryId)}
                    className={styles.country}
                    options={countryOptions}
                    required
                    onChange={handleOnCountryChange(formik, addressObjectName ? `${addressObjectName}.${CountryId}` : CountryId)}
                    selectedKey={addressObjectName ? formik.values[addressObjectName][CountryId] : formik.values[CountryId]}
                    allowFreeform={true}
                    onBlur={() => clearValues([Province, District, Commune, City, PostCode, RegistrationPostOfficeCity, Street,
                        HouseNumber, FlatNumber])} />
                {!isCompany && <div className={styles.fieldWithTooltip}>
                    <TooltipHost
                        content={countryId == 0 ? t('Tooltip.FillIn', { fieldName: t("AddressLabels.Country") }) : ""}
                        id={peselTooltipId}>
                        <TextField
                            label={t("PersonalDetailsLabels.PeselNumber")}
                            disabled={countryId == 0}
                            required={countryId == PolandId}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Pesel}` : Pesel)} />
                    </TooltipHost>
                </div>}
                <Dropdown label={t("PersonalDetailsLabels.Gender")}
                    options={genderOptions}
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${GenderName}` : GenderName)}
                    onChange={handleOnGenderChange(formik, parentObjectName ? `${parentObjectName}.${GenderName}` : GenderName)}
                    defaultSelectedKey={parentObjectName ? formik.values[parentObjectName][GenderName] : formik.values[GenderName]}
                />
                {!isCompany && <DatePicker strings={dataPickerStrings}
                    label={t("PersonalDetailsLabels.BirthDate")}
                    isRequired
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Birthday}` : Birthday)}
                    onSelectDate={handleOnSelectDate(formik, parentObjectName ? `${parentObjectName}.${Birthday}` : Birthday)}
                    textField={{ errorMessage: getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Birthday}` : Birthday)?.errorMessage }}
                    formatDate={formatDate}
                    initialPickerDate={InitialDatePickerDate}
                />}
                {!isCompany && <TextField
                    label={t("PersonalDetailsLabels.PlaceOfBirth")}
                    required
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${BirthPlace}` : BirthPlace)} />}
                {!isCompany && <TextField
                    label={t("PersonalDetailsLabels.FamilyName")}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${FamilyName}` : FamilyName)} />}
                <div className={styles.divider}></div>
                {!isCompany && <div className={styles.fieldWithTooltip}>
                    <TooltipHost
                        content={countryId == 0 ? t('Tooltip.FillIn', { fieldName: t("AddressLabels.Country") }) : ""}
                        id={identityCardTooltipId}>
                        <TextField
                            label={countryId == PolandId ? t("PersonalDetailsLabels.IdentityCardNumber") : t("PersonalDetailsLabels.PassportNumber")}
                            disabled={countryId == 0}
                            required={countryId != PolandId}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${IdentityCard}` : IdentityCard)} />
                    </TooltipHost>
                </div>}
                {!isCompany && <div className={styles.fieldWithTooltip}>
                    <TooltipHost
                        content={countryId == 0 ? t('Tooltip.FillIn', { fieldName: t("AddressLabels.Country") }) : ""}
                        id={identityCardIssuedByTooltipId}>
                        <TextField
                            label={countryId == PolandId ? t("PersonalDetailsLabels.IdentityCardAuthority") : t("PersonalDetailsLabels.PassportAuthority")}
                            disabled={countryId == 0}
                            required={countryId != PolandId}
                            {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${IdentityCardIssuedBy}` : IdentityCardIssuedBy)} />
                    </TooltipHost>
                </div>}
                {!isCompany && <TextField
                    label={t("PersonalDetailsLabels.NipNumber")}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${Nip}` : Nip)}
                    description={t('PersonalDetailsDescription.NipNumber')} />}
            </div>
        </div>)
}

export default PersonalDetailsForm;