import { SET_USER_GUID, UserGuidAction, UserGuidState } from './../actionTypes/userGuidTypes';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';

const localStorageUserGuid = localStorage.getItem(LocalStorageNames.userGuid);

const initialState: UserGuidState = {
    userGuid: localStorageUserGuid ? localStorageUserGuid : ''
};

const userGuidReducer = (state = initialState, action: UserGuidAction): UserGuidState => {
    switch (action.type) {
        case SET_USER_GUID:
            return {
                ...state,
                userGuid: action.payload
            };
        default:
            return state;
    }
};

export default userGuidReducer;
