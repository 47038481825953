import { NotificationType } from '../../models/enums/NotificationType';
import { NotificationAction, NotificationState, SET_NOTIFICATION } from '../actionTypes/notificationTypes';

const initialState: NotificationState = {
    notification: {
        message: '',
        type: NotificationType.none
    }
};

const notificationReducer = (state = initialState, action: NotificationAction): NotificationState => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        default:
            return state;
    }
};

export default notificationReducer;
