import { ITerritorialUnit } from '../../models/interfaces/ITerritorialUnit';

export const SET_PROVINCES = 'SET_PROVINCES';

export interface ProvincesState {
    provinces: ITerritorialUnit[];
}

interface SetProvinces {
    type: typeof SET_PROVINCES;
    payload: ITerritorialUnit[];
}

export type ProvincesAction = SetProvinces;
