/* eslint-disable react/prop-types */
import { FormikProps } from 'formik';
import { INewPerson } from '../../models/interfaces/INewPerson';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import styles from './SigningContractForm.module.scss';
import { IChoiceGroupOptionWithDescription } from './IChoiceGroupOptionWithDescription';
import { SigningContractType } from '../../constants/constants';

interface ISigningContractFormProps {
    formik: FormikProps<INewPerson>;
    title?: string;
    getErrorFieldProps: (formik, field) => void | { errorMessage: string };
    getFieldProps: (formik, field) => void | { errorMessage: string };
    handleGroupOptionOnChangeValue: (formik, fieldName) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => void;
    parentObjectName: string;
}

const SigningContractForm = ({ formik, getFieldProps, parentObjectName, title, handleGroupOptionOnChangeValue }: ISigningContractFormProps): JSX.Element => {
    const { t } = useTranslation();

    const renderChoiceOption = (props, render): JSX.Element => {
        return (
            <div className={styles.radioButton}>
                {render(props)}
                <label className={styles.description}>{props?.description}</label>
            </div>
        );
    };

    const options: IChoiceGroupOptionWithDescription[] = [
        {
            key: SigningContractType.OwnQualifiedSignature,
            text: t('SigningContractType.OwnQualifiedSignatureLabel'),
            description: t('SigningContractType.OwnQualifiedSignatureDescription'),
            onRenderField: renderChoiceOption
        },
        {
            key: SigningContractType.InPersonAtTheOffice,
            text: t('SigningContractType.InPersonAtTheOfficeLabel'),
            description: t('SigningContractType.InPersonAtTheOfficeDescription'),
            onRenderField: renderChoiceOption
        },
        {
            key: SigningContractType.OwnCourierDelivery,
            text: t('SigningContractType.OwnCourierDeliveryLabel'),
            description: t('SigningContractType.OwnCourierDeliveryDescription'),
            onRenderField: renderChoiceOption
        },
        {
            key: SigningContractType.ContractDeliveredByCourier,
            text: t('SigningContractType.ContractDeliveredByCourierLabel'),
            description: t('SigningContractType.ContractDeliveredByCourierDescription'),
            onRenderField: renderChoiceOption
        }
    ];

    return (
        <div className={styles.form}>
            <h2>{title}</h2>
            <div>
                <ChoiceGroup
                    {...getFieldProps(formik, parentObjectName)}
                    className={styles.radioButtonGroup}
                    label={t('SigningContractType.Description')}
                    options={options}
                    required
                    onChange={handleGroupOptionOnChangeValue(formik, parentObjectName)}
                    selectedKey={formik.values[parentObjectName]}
                />
                {getFieldProps(formik, parentObjectName) && <div className={styles.errorMessage}>{getFieldProps(formik, parentObjectName)?.errorMessage}</div>}
            </div>
        </div>
    );
};

export default SigningContractForm;
