import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { ICountry } from '../../models/interfaces/ICountry';
import { CountriesAction, SET_COUNTRIES } from '../actionTypes/countriesTypes';

export const setCountries = (countries: ICountry[]): CountriesAction => {
    localStorage.setItem(LocalStorageNames.countries, JSON.stringify(countries));

    return {
        type: SET_COUNTRIES,
        payload: countries
    };
};
