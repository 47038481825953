import { LocalStorageNames } from '../../models/enums/LocalStorageNames';
import { INewPerson } from '../../models/interfaces/INewPerson';
import { NewPersonFormAction, SET_NEW_PERSON_FORM } from '../actionTypes/newPersonFormTypes';

export const setNewPersonForm = (newPersonForm: INewPerson): NewPersonFormAction => {
    localStorage.setItem(LocalStorageNames.newPersonForm, JSON.stringify(newPersonForm));

    return {
        type: SET_NEW_PERSON_FORM,
        payload: newPersonForm
    };
};
