import { SET_LANGUAGE, LangAction, LangState } from '../actionTypes/langTypes';
import { LanguageAbbreviation } from '../../models/enums/LanguageAbbreviation';
import { LocalStorageNames } from '../../models/enums/LocalStorageNames';

const localStorageLang = localStorage.getItem(LocalStorageNames.language);

const initialState: LangState = {
    language: localStorageLang ? LanguageAbbreviation[localStorageLang] : LanguageAbbreviation.pl
};

const langReducer = (state = initialState, action: LangAction): LangState => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };
        default:
            return state;
    }
};

export default langReducer;
