import React, { useEffect, useRef, useState } from 'react';
import './styles/App.scss';
import { Switch, Route, useLocation, } from 'react-router-dom';
import NewContractPage from './container/newContractPage/NewContractPage';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from './redux/actions/langActions';
import { setUserGuid } from './redux/actions/userGuidActions';
import { setNewPersonForm } from './redux/actions/newPersonFormActions';
import { setCountries } from './redux/actions/countriesActions';
import { setProvinces } from './redux/actions/provincesActions';
import { LanguageAbbreviation } from './models/enums/LanguageAbbreviation';
import { ThemeProvider } from '@fluentui/react';
import { primaryTheme } from './styles/Themes';
import { RootState } from './redux';
import { RequestService } from './services/RequestService';
import { IBaseNewPerson } from './models/interfaces/IBaseNewPerson';
import { NewPerson } from './models/NewPerson';
import { Endpoints } from './services/const.routes';
import { INewPerson } from './models/interfaces/INewPerson';
import { LocalStorageNames } from './models/enums/LocalStorageNames';
import { ICountry } from './models/interfaces/ICountry';
import { ITerritorialUnit } from './models/interfaces/ITerritorialUnit';

const userIdQueryName = "userid";
const baseNewPersonRequestService = new RequestService<IBaseNewPerson>();
const countryRquestService = new RequestService<ICountry>();
const territorialUnitsRequestService = new RequestService<ITerritorialUnit>();

const App = (): JSX.Element => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.lang);
  const { userGuid } = useSelector((state: RootState) => state.userGuid);
  const isMountedRef = useRef<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadedAllData, setIsLoadedAllData] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const loadBaseNewPerson = async () => {
    const browserLanguage = navigator.language;

    if (browserLanguage?.toLocaleLowerCase() === "pl") {
      i18n.changeLanguage(LanguageAbbreviation.pl);
    } else {
      i18n.changeLanguage(LanguageAbbreviation.en);
    }

    const query = new URLSearchParams(location.search?.toLowerCase());

    if (query.has(userIdQueryName)) {
      setMessage(t("Messages.Loading"));
      const userIdQuery = query.get(userIdQueryName) as string;
      clearStorageIfNewUser(userIdQuery)

      try {
        setIsLoading(true);
        const baseNewPerson = await baseNewPersonRequestService.get(`${Endpoints.NewPersonForm}/${userIdQuery}`);

        if (baseNewPerson?.isFilled) {
          throw new Error(t("Messages.LoadingError"));
        }

        const lang = baseNewPerson?.language?.code?.toLocaleLowerCase();
        dispatch(setLanguage(lang ? LanguageAbbreviation[lang] : LanguageAbbreviation.pl));
        i18n.changeLanguage(lang ?? LanguageAbbreviation.en);

        const localStorageNewPersonForm = localStorage.getItem(LocalStorageNames.newPersonForm);
        const newPersonForm = localStorageNewPersonForm ? JSON.parse(localStorageNewPersonForm) as INewPerson : null;
        const newPerson = provisionNewPersonForm(newPersonForm, baseNewPerson);

        dispatch(setNewPersonForm(newPerson));
        setIsLoadedAllData(true);
        setIsLoading(false);
      } catch (exception) {
        setMessage(t("Messages.LoadingError"));
        setIsLoading(false);
      }
    } else {
      setMessage(t("Messages.WrongUrl"));
      i18n.changeLanguage(language ?? LanguageAbbreviation.pl);
    }
  }

  const loadCountries = async () => {
    try {
      const countries = await countryRquestService.getList(Endpoints.Countries);
      dispatch(setCountries(countries));
    } catch (exception) {
      setMessage(t("Messages.LoadingError"));
    }
  }

  const provisionNewPersonForm = (currentNewPerson: INewPerson | null, baseNewPerson: IBaseNewPerson | null): NewPerson => {
    if (!currentNewPerson) {
      currentNewPerson = new NewPerson();
    }

    if (baseNewPerson?.user != null) {
      currentNewPerson.firstName = baseNewPerson?.user.firstName;
      currentNewPerson.secondName = baseNewPerson?.user?.secondName ? baseNewPerson?.user.secondName : "";
      currentNewPerson.lastName = baseNewPerson?.user.lastName;
      currentNewPerson.domainLogin = baseNewPerson?.user?.email.slice(0, baseNewPerson?.user?.email.indexOf("@"));
      currentNewPerson.isCompany = baseNewPerson?.isInvoiceContractType;
    }

    return currentNewPerson;
  }

  const loadProvinces = async () => {
    try {
      const provinces = await territorialUnitsRequestService.getList(Endpoints.Provinces);
      dispatch(setProvinces(provinces))
    } catch (exception) {
      setMessage(t("Messages.LoadingError"));
    }
  }

  const clearStorageIfNewUser = (userIdQuery: string) => {
    if (userGuid != userIdQuery) {
      dispatch(setNewPersonForm(new NewPerson()));
      dispatch(setUserGuid(userIdQuery));
    }
  }

  useEffect(() => {
    isMountedRef.current = true;

    if (isMountedRef.current) {
      loadBaseNewPerson();
      loadCountries();
      loadProvinces();
    }

    return () => {
      isMountedRef.current = false
    };
  }, [])

  return (
    <ThemeProvider applyTo='body' theme={primaryTheme}>
      <Switch>
        <Route exact path="/" component={() => (<NewContractPage isLoading={isLoading} isLoadedAllData={isLoadedAllData} message={message} />)} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;