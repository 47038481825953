import { TextField } from '@fluentui/react/lib/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INewPerson } from '../../models/interfaces/INewPerson';
import styles from './PhoneNumbersForm.module.scss';

interface IPhoneNumbersFormProps {
    formik: FormikProps<INewPerson>;
    getFieldProps: (formik, field) => void;
    parentObjectName?: string;
    title?: string;
}

const EmergencyPhoneNumber = "emergencyPhoneNumber";

const PhoneNumbersForm = ({ formik, getFieldProps, parentObjectName, title }: IPhoneNumbersFormProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={styles.phoneNumbersWrapper}>
            <h2>{title}</h2>
            <div className={styles.formsControlsWrapper}>
                <TextField
                    label={t("PhoneNumbersLabels.IcePhoneNumber")}
                    {...getFieldProps(formik, parentObjectName ? `${parentObjectName}.${EmergencyPhoneNumber}` : EmergencyPhoneNumber)}
                    description={t("PhoneNumbersDescription.IcePhoneNumber")} />
            </div>
        </div>)
}

export default PhoneNumbersForm;